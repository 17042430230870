* {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
}
section {
  width: 100vw;
  height: max(100vh, fit-content);
  max-width: 100%;
  z-index: 0;
  position: relative;
}
a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  text-decoration: underline;
}
.lead {
  margin: 0;
  padding: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  background-image: url("https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1844&q=80");
}
.lead:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  z-index: 3;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
h1 {
  font-family: "eight";
}
h2 {
  font-family: "garamond-premier-pro-caption";
}
h4 {
  font-family: "pt-mono";
  font-weight: 100;
}
.lead h1,
.lead h2,
.lead button {
  z-index: 4;
  color: white;
  text-align: center;
  z-index: 4;
}
.lead h1 {
  margin: 0;
  font-size: 4.5em;
  -webkit-text-stroke: 2px #a5b4fc;
}
.lead h2 {
  font-size: 2.2em;
}
button:not(.map-container button) {
  color: white;
  background-color: black;
  border: none;
  font-family: "eight";
  font-size: 1.65em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border-radius: 2em;
  padding: 0.25em 1.5em;
  margin-top: 5em;
  transition: 0.5s;
  cursor: pointer;
}
button:hover,
button:focus {
  transform: scale(0.95);
}
.lead button {
  background-color: #a5b4fc;
}
button .icon {
  font-size: 0.8em;
}
.reason h1,
.reason h2 {
  padding: 0 1.5rem;

  text-align: left;
}
.reason h1 {
  padding-top: 0.5em;
  font-size: 2.55em;
  max-width: 95%;
  white-space: pre-wrap;
}
.reason h2 {
  font-size: 2em;
}
.photo-wrapper img {
  object-fit: contain;
  height: 15em;
  border: 5px solid black;
  border-radius: 2em;
}
h3 {
  font-family: "pt-mono";
}

@media screen and (max-width: 800px) {
  .photo-wrapper img {
    height: 10em !important;
  }
  .slideshow {
    height: fit-content !important;
    width: min(30em, 80%);
    object-fit: contain !important;
  }
  .slideshow img {
    width: 100% !important;
    height: 100% !important;
  }
  .view-sources {
    margin-left: 0.75em !important;
    margin-bottom: 1em !important;
  }
}
@media screen and (max-width: 500px) {
  .lead h1 {
    font-size: 4em;
  }
  .photo-wrapper img {
    height: 7em !important;
  }
  .slideshow {
    height: fit-content !important;
    width: min(30em, 80%);
    object-fit: contain !important;
  }
  .slideshow img {
    width: 100% !important;
    height: 100% !important;
  }
  .reason h1 {
    font-size: 2em;
  }
}
.tmc-container {
  max-width: 90%;
  margin-right: auto;
  padding: 0;
  margin-left: 0;
}
.h-stack {
  flex-wrap: wrap;
  display: flex;
}
.reason p {
  flex: 1;
  font-family: "pt-mono";
  font-size: 1.35em;
  padding: 0 1.5rem;
  padding-right: .5rem;
  min-width: 40%;
  padding-bottom: 1em;
}
.v-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.tmc h3 {
  font-family: "pt-mono";
  font-size: 1.5em;
  padding: 0.25em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3em;
  max-width: 15em;
  margin-left: auto;
  text-align: left;
}
.method,
.results {
  background-color: #818cf8;
  color: white;
  width: 100%;
}
.results {
  padding-right: 0.5em;
  background-color: #3730a3 !important;
}
.method.active {
  background-color: #a5b4fc !important;
  font-style: italic;
}
.method {
  cursor: pointer;
}
.hide {
  display: none;
}
.h-stack-around {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon {
  font-size: 1.5em;
  margin: 0;
}
footer {
  overflow-x: scroll;
}
footer h1,
footer h2 {
  max-width: 90%;
}
.list-item {
  padding: 0.25em 0.65em;
  gap: 0.5em;
  align-items: center;
  padding-bottom: 0;
}
.map-container {
  overflow: hidden !important;
  height: 70vh !important;
  width: min(80%) !important;
  margin: 0 auto;
  margin-top: 1em !important;
}

.mapboxgl-popup-close-button {
  aspect-ratio: 1/1 !important;
  background-color: black !important;
  font-size: 20px !important;
  position: absolute;
  z-index: 999;
  width: 25px;
  height: 25px;

  padding: 0px !important;
  border-radius: 10000% !important;
  color: white !important;
  fill: #f97316 !important;
}
.popup-box {
  color: black;
}
.popup-box h2 {
  padding: 0.5em 0;
  text-align: center;
}
.popup-box h4 {
  font-size: 1.2em;
  margin-bottom: 0.75em;
}
.stationIcon {
  height: 2em;
  width: 2em;
  aspect-ratio: 1/1;
  background-size: 100%;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/1/1e/WMATA_Metro_Logo.svg") !important;
}
.buildingsIcon {
  height: 4em;
  width: 4em;
  aspect-ratio: 1/1;
  background-size: 100%;
  content: "";
  background-repeat: no-repeat;
  background-image: url("../public/images/BuildingsIcon.svg") !important;
}
